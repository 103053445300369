import Vue from 'vue';
import { Toast } from 'vant';
import axios from 'axios';

Vue.use(Toast);

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

// axios.defaults.headers.common['token'] = sessionStorage.getItem('token');
axios.defaults.headers.common['platformId'] = sessionStorage.getItem('platformId');


const service = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    const success = res.data.success;
    if (success) {
      return res.data;
    } else {
      Toast(res.data.rtnMessage);
      // return Promise.reject(res.data);
      return res.data;
    }
  },
  (error) => {
    console.log(error);
    if (String(error).includes('timeout')) {
      // Toast.fail('请求超时');
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default service;
