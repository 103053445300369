import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userInfo: {},
    platformId: 1, //1久来米 2众城
    cascaderShow: false,
    cascaderList: [],
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userInfo = payload.userInfo;
      console.log('state.userinfo', state.userInfo);
    },
    setPlatformId(state, id) {
      state.platformId = id;
    },
    setCascaderShow(state, v) {
      state.cascaderShow = v;
    },
    setCascaderList(state, list) {
      state.cascaderList = list;
    },
  },
});

export default store;
