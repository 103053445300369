import Vue from 'vue';
import Router from 'vue-router';

import Home from '../pages/Home.vue';
import Loading from '../pages/Loading.vue';
import Authentication from '../pages/Authentication.vue';
import Profile from '../pages/Profile.vue';
import MyInfo from '../pages/MyInfo.vue';
import Contract from '../pages/Contract.vue';
import Salary from '../pages/Salary.vue';
import SalaryDetail from '../pages/SalaryDetail.vue';
import Social from '../pages/Social.vue';
import SocialDetail from '../pages/SocialDetail.vue';
import Sign from '../pages/Sign.vue';
import SignDetail from '../pages/SignDetail';

Vue.use(Router);

const router = new Router({
  routes: [
    // {
    //   path: '/',
    //   redirect: '/home',
    // },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
    },
    {
      path: '/authentication',
      name: 'authentication',
      component: Authentication,
    },
    {
      path: '/loading',
      name: 'loading',
      component: Loading,
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: {
        title: '我的',
      },
    },
    {
      path: '/myInfo',
      name: 'myInfo',
      component: MyInfo,
      meta: {
        title: '个人信息',
      },
    },
    {
      path: '/contract',
      name: 'contract',
      component: Contract,
      meta: {
        title: '合同管理',
      },
    },
    {
      path: '/salary',
      name: 'salary',
      component: Salary,
      meta: {
        title: '工资条',
      },
    },
    {
      path: '/salaryDetail',
      name: 'salaryDetail',
      component: SalaryDetail,
      meta: {
        title: '工资条',
      },
    },
    {
      path: '/social',
      name: 'social',
      component: Social,
      meta: {
        title: '社保公积金',
      },
    },
    {
      path: '/socialDetail',
      name: 'socialDetail',
      component: SocialDetail,
      meta: {
        title: '社保公积金',
      },
    },
    {
      path: '/sign',
      name: 'sign',
      component: Sign,
      meta: {
        title: '签约管理',
      },
    },
    {
      path: '/signDetail',
      name: 'signDetail',
      component: SignDetail,
      meta: {
        title: '签约管理',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  let t = to.meta.title;
  document.title = t ? t : 'HRO';
  next();
});

export default router;
