<template>
  <div class="Social">
    <div v-if="!list.length && !loading && finished" class="empty">
      <van-empty description="暂无数据" />
    </div>
    <div v-else class="list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="list">
          <div class="common-list-item" v-for="item in list" :key="item.id">
            <div class="top">
              <div class="date">
                <van-icon name="notes-o" />{{
                  $dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss')
                }}
              </div>
              <div class="btn" @click="toDetail(item)">
                查看详情<van-icon name="arrow" />
              </div>
            </div>
            <div class="bot">
              <p class="flex-para">
                <span>{{ item.month }}</span
                ><span>{{ formatAmount(item.sumPer) }} 元</span>
              </p>
            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Empty, NavBar, Toast } from 'vant';
import formatAmount from '../utils/formatAmount';
Vue.use(Empty).use(NavBar).use(Toast);

export default {
  name: 'Social',
  data() {
    return {
      formatAmount,
      status: 0,
      list: [],
      pageNo: 1,
      loading: false,
      finished: false,
      total: 0,
      totalAmount: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {},
  methods: {
    onLoad() {
      this.getTaskList();
    },
    async getTaskList() {
      this.finished = false;
      this.loading = true;
      const data = await this.$axios.post(
        '/tax/wechatController/wechat/getSocialFund',
        {
          openId: this.userInfo.openId,
          page: {
            page: this.pageNo,
          },
        }
      );
      if (data.success) {
        const { list, total, pages } = data.data;
        this.list = [...this.list, ...list];
        this.total = total;
        if (this.pageNo < pages) {
          this.pageNo++;
        } else {
          this.finished = true;
        }
      }
      this.loading = false;
      this.showLoading = false;
    },
    toDetail(obj) {
      sessionStorage.setItem('socialDetail', JSON.stringify(obj));
      this.$router.push('socialDetail');
    },
  },
};
</script>

<style lang="scss">
@import '../styles/vars';

.Social {
  background: #fff;
  min-height: 100vh;

  .list {
    padding: 0.08rem;
  }

  .flex-para {
    display: flex;
    justify-content: space-between;
  }
}
</style>
