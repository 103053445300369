<template>
  <div class="agreement">
    <template v-if="platformId === 1">
      <div v-if="type == 'privacy'" id="content" class="content">
        <p class="title">“久来米”平台隐私政策协议</p>
        <p><b>【特别提示】</b></p>
        <p>
          本政策仅适用于【上海赞华实业有限公司】（以下简称“赞华实业”）提供的产品和服务及其延伸的功能（以下简称“赞华实业服务”），包括“久来米”平台、网站、H5、小程序向您提供的各项产品和服务。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用，该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。
        </p>
        <p>
          请仔细阅读《隐私政策》（尤其是粗体内容）并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可通过《隐私政策》中的联系方式咨询我们。
        </p>
        <p><b>引言</b></p>
        <p>
          欢迎您使用赞华实业服务！我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《隐私政策》（“本隐私政策”）向您说明在您使用我们的产品与/或服务时我们如何收集、使用、保存、共享和转移这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
        </p>
        <p>
          本隐私政策与您所使用的赞华实业服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。
        </p>
        <p><b>一、我们如何收集和使用您的个人信息</b><b></b></p>
        <p>
          个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
        </p>
        <p>
          我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其他用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
        </p>
        <p><b>（一）您须授权我们收集和使用您个人信息的情形</b><b></b></p>
        <p>
          我们的产品与/或服务包括一些核心功能，这些功能包含了税务代理服务的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：
        </p>
        <p>1、税务代理服务所必须的功能</p>
        <p>（1）账号注册、登录与认证</p>
        <p>
          当您使用“久来米”平台线上税务代理服务系统时，你需要注册“久来米”平台账号。当您注册“久来米”平台账号时，您至少需要向我们提供您准备使用的“久来米”平台账号名称、密码、手机号码，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。您的账号名为您的默认昵称，您可以修改和补充您的昵称、性别、生日以及您的实名认证相关信息，这些信息均属于您的“账号信息”。您补充的账号信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，将会影响您使用税务代理服务的基本功能。
        </p>
        <p>
          当您成功注册“久来米”平台账号后，可以使用“久来米”平台账号登录““久来米”平台”，登录时您需要向我们提供您的“久来米”平台账号信息。若您不提供这类信息，您将无法登录“久来米”平台，此时您为开展税务代理服务使用浏览和搜索功能，我们将不会收集您的上述个人信息。
        </p>
        <p>
          您授权“久来米”平台将您的个人信息发送给您指定的服务商。服务商再通过“久来米”平台将您的服务外包发送给服务需求方。
        </p>
        <p>（2）下单及订单管理</p>
        <p>
          当您指定的服务商与需求方达成一致意向时，您应根据订单要求向需求方提供服务。您需要在订单中至少填写您的姓名、邮寄地址或电子邮箱以及手机号码，同时该订单中会载明订单号、下单时间、服务内容、服务费。
        </p>
        <p>（3）交付产品、支付功能</p>
        <p>
          因您将所提供的服务转包给服务商，服务商将您的服务提供给需求方后，需求方应向服务商支付服务费，服务商再将所收取的服务费通过银行转账汇至您的银行账户。
        </p>
        <p>（4）客服与售后功能</p>
        <p>
          我们的电话客服和售后功能会使用您的账号信息和订单信息。为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更服务地址、联系人或联系电话。
        </p>
        <p>2、保障交易安全所必须的功能</p>
        <p>
          为保障您使用我们的产品与/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、设备序列号、操作系统及软件版本、设备状态、网络状况）、历史上网记录、日志信息、面部信息、IP地址、位置信息、浏览信息、订单信息、wifi信息、IMSI、SIM卡信息、电池使用情况来识别是否为真实自然人用户、是否为异常地点登录、判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对赞华实业系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用赞华实业服务过程中对您的服务及账号安全进行保护。
        </p>
        <p><b>（二）您个人信息使用的规则</b><b></b></p>
        <p>
          1、我们会根据本隐私政策的内容，为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
        </p>
        <p>
          2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。
        </p>
        <p>
          3、当我们展示您的个人信息时，我们会采用包括去标识化或者匿名化处理方式对您的信息进行脱敏，以保护您的信息安全。
        </p>
        <p>
          4、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的明示同意。
        </p>
        <p>
          请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。
        </p>
        <p><b>二、我们如何对外提供您的个人信息</b><b></b></p>
        <p><b>（一）共享</b><b></b></p>
        <p>
          1、我们不会与赞华实业以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
        </p>
        <p>（1）事先获得您明确的同意或授权；</p>
        <p>（2）根据法律法规、行政及司法部门强制性要求进行提供；</p>
        <p>
          （3）在法律法规允许的范围内，为维护赞华实业或合作伙伴、您或其他赞华实业用户或社会公众利益、财产或安全免遭损害而有必要提供；
        </p>
        <p>
          （4）只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
        </p>
        <p>（5）应您需求为您处理您与他人的纠纷或争议；</p>
        <p>
          （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
        </p>
        <p>（7）基于学术研究而使用；</p>
        <p>（8）基于符合法律法规的社会公共利益、突发公共卫生事件而使用。</p>
        <p>
          2、为向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账号与交易安全，我们可能会将您的个人信息与我们的合作伙伴共享，共享内容及目的。我们的关联方或者合作伙伴如要改变个人信息的处理目的，将再次征求您的授权同意。
        </p>
        <p>
          请您注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您共享时谨慎考虑并决定。
        </p>
        <p><b>（二）委托处理</b><b></b></p>
        <p>
          我们可能委托授权合作伙伴处理您的个人信息，以便向您提供相应的产品或服务。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供产品或服务所必要的个人信息。如果我们的合作伙伴将您的个人信息用于我们未委托的用途，其将单独征得您的同意。
        </p>
        <p>我们的合作伙伴包括以下类型：</p>
        <p>
          （1）提供技术服务的供应商。我们可能会将您的个人信息提供给支持我们功能的第三方，包括为我们提供基础设施技术服务、数据分析服务及数据处理服务等的供应商。
        </p>
        <p>
          （2）广告、分析服务类合作伙伴。我们会遵守法律法规及行业通用安全技术，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。
        </p>
        <p>
          对我们委托合作伙伴处理个人信息的，我们会与其签署数据保护协议，要求其按照协议约定、本隐私政策以及相关法律法规要求来处理个人信息。
        </p>
        <p><b>（三）转移</b><b></b></p>
        <p>
          在因合并、分立、解散、被宣告破产等原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式，要求接收您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p><b>（四）公开披露</b><b></b></p>
        <p>
          我们仅会在以下情况，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
        </p>
        <p>（1）根据您单独同意的授权范围披露相应个人信息；</p>
        <p>
          （2）如果您严重违反法律法规或者相关协议、规则，我们可能会披露您的相关违规行为及我们对您采取的措施。
        </p>
        <p><b>三、我们如何保护和保存您的个人信息</b><b></b></p>
        <p><b>（一）我们保护您个人信息的技术与措施</b><b></b></p>
        <p>
          我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
        </p>
        <p>1、数据安全技术措施</p>
        <p>
          我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
        </p>
        <p>2、为保护个人信息采取的其他安全措施</p>
        <p>
          通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用；通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制；建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
        </p>
        <p>
          互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。在通过赞华实业与第三方进行网上产品与/或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
        </p>
        <p>3、安全事件处置</p>
        <p>
          为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
        </p>
        <p>
          一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时，我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        </p>
        <p>
          如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账号及密码发生泄露，请您立即通过本隐私政策“七、如何联系我们”中明确的联系方式联络我们，以便我们采取相应措施。
        </p>
        <p><b>（二）您个人信息的保存</b><b></b></p>
        <p>1、您的个人信息将存储于中华人民共和国境内。</p>
        <p>
          2、在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
        </p>
        <p>
          3、请您注意，当您成功注销“久来米”平台账号后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
        </p>
        <p>
          4、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
        </p>
        <p><b>四、您如何管理您的个人信息</b><b></b></p>
        <p>
          赞华实业非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
        </p>
        <p>1、访问和更正您的个人信息</p>
        <p>
          除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括（1）您的账号信息；（2）您的收件信息；（3）您的订单信息（4）您的浏览信息；（5）您的评论信息；（6）您的发票信息；（7）您的档案信息。
        </p>
        <p>
          对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
        </p>
        <p>2、删除您的个人信息</p>
        <p>
          您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。
        </p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>（1）如果我们处理个人信息的行为违反法律法规；</p>
        <p>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p>（3）如果我们处理个人信息的行为严重违反了与您的约定；</p>
        <p>（4）如果您不再使用我们的产品或服务，或您注销了“久来米”平台账号；</p>
        <p>（5）如果我们终止服务及运营，或者保存期限已届满。</p>
        <p>
          若我们决定响应您的删除请求，我们还将同时尽可能通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体已获得您的独立授权。
        </p>
        <p>
          请您注意，当您成功注销“久来米”平台账号后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到可以删除或实现匿名化。
        </p>
        <p>3、改变您授权同意的范围或撤回您的授权</p>
        <p>
          您可以通过删除信息、关闭设备功能、在赞华实业网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账号的方式，撤回我们继续收集您个人信息的全部授权。
        </p>
        <p>
          请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </p>
        <p>4、注销账号</p>
        <p>
          您可以在我们的产品中直接申请注销账号。您注销账号后，我们将停止为您提供产品与/或服务，并根据适用法律的要求删除您的个人信息，或进行匿名化处理。
        </p>
        <p>5、获取您的个人信息副本</p>
        <p>
          您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您随时联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供相应的个人信息副本。
        </p>
        <p>6、响应您的请求</p>
        <p>
          如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息或者获取个人信息副本，或您认为赞华实业存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以发送电子邮件至
          &nbsp;service@zanhua.com.cn
          或通过本隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
        </p>
        <p><b>五、未成年人的个人信息保护</b><b></b></p>
        <p>
          1、非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您监护人的同意。赞华实业根据国家相关法律法规的规定保护未成年人的个人信息。
        </p>
        <p>
          2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
        </p>
        <p>
          3、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
        </p>
        <p><b>六、通知和修订</b><b></b></p>
        <p>
          1、为给您提供更好的服务以及随着赞华实业业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在“久来米”网站、“久来米”移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新。
        </p>
        <p>
          2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
        </p>
        <p>本隐私政策所指的重大变更包括但不限于：</p>
        <p>
          （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        </p>
        <p>
          （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
        </p>
        <p>（3）个人信息共享、转移或公开披露的主要对象发生变化；</p>
        <p>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>
          （5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
        </p>
        <p>（6）个人信息安全影响评估报告表明存在高风险时。</p>
        <p>
          3为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。
        </p>
        <p><b>七、如何联系我们</b><b></b></p>
        <p>
          1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。
        </p>
        <p>个人信息保护负责人邮箱：service@zanhua.com.cn</p>
        <p>联系电话：【400-090-6916】</p>
        <p>
          联系地址：上海市浦东新区浦东南路855号世界广场37楼
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <p>
          2、一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
        </p>
        <p>
          附：个人信息及敏感个人信息示例个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。（本定义出自于《中华人民共和国个人信息保护法》）。
        </p>
      </div>
      <div v-else id="content" class="content">
        <p class="title">“久来米”平台注册服务协议</p>
        <p>亲爱的用户</p>
        <p style="text-indent: 21pt">
          欢迎您（即本协议项下的“用户”）与上海琪驭精工科技有限公司签署《久来米平台注册服务协议》（以下简称“本协议”），感谢您选用“久来米”品牌，通过“久来米”平台/软件、互联网及线下向您提供的各项服务。
        </p>
        <p style="text-indent: 21pt">
          您访问本网站、使用本网站的任何服务、在本网站内与其他用户的交流互动、在本网站内发布任何信息或内容以及使用其他用户发布的任何信息或内容等一切与本网站有关的活动和行为，均受到以下协议条款的约束和限制，请您务必仔细阅读。&nbsp;特别是采用粗体字标注的免除或限制责任的条款、法律使用和争议解决条款。
        </p>
        <p style="text-indent: 21pt">
          【特别提示】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他“久来米”允许的方式实际使用“久来米”平台服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与“久来米”平台达成协议。您承诺接受并遵守本协议的所有约定，届时您不应以未阅读本协议的内容或者未获得久来米对您问询的解答等理由，主张本协议无效，或要求撤销本协议。本网站有权随时变更本协议并在本网站上予以公告。经修订的条款一经在本网站的公布后，立即自动生效。如您不同意相关变更，必须停止使用本网站。若您对本协议的条款或其他变更内容，存在任何疑问，请您立刻联系久来米平台工作人员澄清，若您不同意本协议，您应立即停止注册或停止使用久来米平台以及平台服务。
        </p>
        <p>一、协议范围</p>
        <p>
          1、本协议由您和“久来米”平台（域名为“https://wap.zanhua.com.cn/”、“久来米微信小程序”或其他H5等开放平台,以下简称“久来米”平台；）运营方上海琪驭精工科技有限公司订立，本协议具有合同效力。
        </p>
        <p>
          2、除另行明确声明外，“久来米”平台服务包含任何“久来米”平台及其关联公司、第三方服务商提供的基于互联网以及移动互联网的相关服务，且均受本协议约束。如果您不同意本协议的约定，您应立即停止注册或停止使用“久来米”平台服务。
        </p>
        <p>
          3、本协议内容包括协议正文、法律声明、《“久来米”平台服务规则》及所有“久来米”平台已经发布或将来可能发布的各类规则、公告或通知（以下合称“‘久来米’平台规则”或“规则”）。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。
        </p>
        <p>
          4、“久来米”平台有权依据国家法律法规调整的要求和公平交易的商业原则及时地制订、修改本协议及各类规则，并以网站公示的方式进行变更公告，无需另行单独通知您。变更后的协议和规则一经在网站公布后，立即或在公告明确的特定时间自动生效。若您在前述变更公告后继续使用“久来米”平台服务的，即表示您已经阅读、理解并接受经修订的协议和规则。若您不同意相关变更，应当立即停止使用“久来米”平台服务。
        </p>
        <p>二、注册与账户</p>
        <p>1、主体资格</p>
        <p>
          您确认，在您完成注册程序或以其他“久来米”平台允许的方式实际使用“久来米”平台服务时，您应当符合下列条件之一:
        </p>
        <p>1）年满十八岁，并具有民事权利能力和民事行为能力的自然人；</p>
        <p>
          2）无民事行为能力人或限制民事行为能力人应在其监护人的监护下使用“久来米”平台服务；
        </p>
        <p>
          3）根据中国法律、法规、行政规章成立并合法存在的机关、企事业单位、个体工商户、社团组织和其他组织。
        </p>
        <p>2、用户的帐号、密码和安全性</p>
        <p>
          您一旦注册成功成为用户，您将得到一个密码和帐号。如果您未保管好自己的帐号和密码而对您、“久来米”平台或第三方造成的损害，您将负全部责任。另外，每个用户都要对其帐户中的所有活动和事件负全责。您可随时改变您的密码和图标，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或安全漏洞的情况，立即通告“久来米”平台。
        </p>
        <p>三、“久来米”平台服务</p>
        <p>
          1、您可以在“久来米”平台上发布需求/服务、查询需求/服务、达成交易意向并进行交易、对其他会员进行评价、参加“久来米”平台组织的活动以及使用其它信息服务及技术服务，具体以所开通的平台提供的服务内容为准。
        </p>
        <p>
          2、您在“久来米”平台上交易过程中与其他会员发生交易纠纷时，一旦您或其它会员任一方或双方共同提交“久来米”平台要求调查处理，则“久来米”平台作为独立第三方，有权根据单方判断做出调查处理决定，您了解并同意接受“久来米”平台的判断和调查处理决定。
        </p>
        <p>
          3、您了解并同意，“久来米”平台有权应政府部门（包括司法及行政部门）的要求，向其提供您向“久来米”平台提供的用户信息和交易记录等必要信息。如您涉嫌侵犯他人知识产权等合法权益，则“久来米”平台亦有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供您必要的身份信息。
        </p>
        <p>
          4、您应当自行承担因交易产生或取得的相关费用，并依法纳税，平台不承担代扣义务。
        </p>
        <p>5、拒绝提供担保和免责声明</p>
        <p>
          您明确同意使用“久来米”平台服务的风险由您个人承担。服务提供是建立在免费的基础上。“久来米”平台明确表示不提供任何类型的担保，不论是明确的或隐含的，但是对商业性的隐含担保，特定目的和不违反规定的适当担保除外。“久来米”平台不担保服务一定能满足用户的要求，也不担保服务不会受中断，对服务的及时性、安全性、真实性、出错发生都不作担保。“久来米”平台拒绝提供任何担保，包括信息能否准确、及时、顺利地传送。用户理解并接受下载或通过“久来米”平台产品服务取得的任何信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其它任何风险。“久来米”平台对在服务网上得到的任何商品购物服务、交易进程、招聘信息，承包过程、成果审核等都不作担保。用户不会从“久来米”平台收到口头或书面的意见或信息，“久来米”平台也不会在这里作明确担保。
        </p>
        <p>四、“久来米”平台服务使用规范</p>
        <p>
          1、在“久来米”平台上使用“久来米”平台服务过程中，你承诺遵守以下约定：
        </p>
        <p>
          1）实施的所有行为均遵守国家法律、法规等规范性文件及“久来米”平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不偷逃应缴税费，不违反本协议及相关规则。
        </p>
        <p>
          2）在与其他会员交易过程中，遵守诚实信用原则，不采取不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。
        </p>
        <p>
          3）不发布国家禁止销售的或限制销售的服务信息（除非取得合法且足够的许可），不发布涉嫌侵犯他人知识产权或其它合法权益的服务信息，不发布违背社会公共利益或公共道德或“久来米”平台认为不适合在“久来米”平台上销售的服务信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。
        </p>
        <p>
          4）不采取不正当手段（包括但不限于虚假需求、互换好评等方式）提高自身或他人评价，或采用不正当手段恶意评价其他用户，降低其他人评价。
        </p>
        <p>
          5）未经公司书面许可，不对“久来米”平台上的任何数据作商业性利用，包括但不仅限于在未经“久来米”平台事先书面同意的情况下，以复制、传播等任何方式使用“久来米”平台站上展示的资料。
        </p>
        <p>
          6）不使用任何装置、软件或例行程序干预或试图干预“久来米”平台的正常运作或正在“久来米”平台上进行的任何交易、活动。你不得采取任何将导致不合理的庞大数据负载加诸“久来米”平台网络设备的行为。
        </p>
        <p>2、您了解并同意：</p>
        <p>
          1）您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保“久来米”平台免于因此产生任何损失或增加费用。
        </p>
        <p>
          2）基于维护“久来米”平台交易秩序及交易安全的需要，“久来米”平台有权在发生恶意交易等扰乱市场正常交易秩序的情形下，执行强制关闭相应交易订单等操作。
        </p>
        <p>
          3）&nbsp;经国家行政或司法机关的生效法律文书确认你存在违法或侵权行为，或者“久来米”平台根据自身的判断，认为您的行为涉嫌违反法律法规的规定或涉嫌违反本协议和/或规则的条款的，则“久来米”平台有权在“久来米”平台上公示您的该等涉嫌违法或违约行为及“久来米”平台已对您采取的措施。
        </p>
        <p>
          4）对于您在“久来米”平台上发布的涉嫌违法、涉嫌侵犯他人合法权利或违反本协议和规则的信息，“久来米”平台有权不经通知你即予以删除，且按照规则的规定进行处罚。
        </p>
        <p>
          5）对于您违反本协议项下承诺，或您在“久来米”平台上实施的行为，包括你未在“久来米”平台上实施但已经对“久来米”平台及其用户产生影响的行为，“久来米”平台有权单方认定您行为的性质及是否构成对本协议规则的违反，并根据单方认定结果适用规则予以处理或终止向您提供服务，且无须征得您的同意或提前通知予您。您应自行保存与您行为有关的全部证据，并应对无法提供充要证据而承担的不利后果。
        </p>
        <p>
          6）如您涉嫌违反有关法律或者本协议之规定，使“久来米”平台遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿“久来米”平台因此造成的损失发生的费用，包括合理的律师费用。
        </p>
        <p>3、您了解并同意：</p>
        <p>
          您单独承担发布内容的责任。您对服务的使用是根据所有适用于服务的地方法律、国家法律和国际法律标准的。您承诺:
        </p>
        <p>
          1）在“久来米”平台的网页上发布信息或者利用“久来米”平台的服务时必须符合中国有关法规，不得在“久来米”平台的网页上或者利用“久来米”平台的服务制作、复制、发布、传播以下信息:
        </p>
        <p>a)反对宪法所确定的基本原则的;</p>
        <p>b)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>c)损害国家荣誉和利益的；</p>
        <p>d)煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>e)破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>f)散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>g)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>h)侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>i)含有法律、行政法规禁止的其他内容的。</p>
        <p>
          2）在“久来米”平台的网页上发布信息或者利用“久来米”平台的服务时还必须符合其他有关国家和地区的法律规定以及国际法的有关规定。
        </p>
        <p>3）不利用“久来米”平台的服务从事以下活动:</p>
        <p>a)未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
        <p>b)未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
        <p>
          c)未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
        </p>
        <p>d)故意制作、传播计算机病毒等破坏性程序的；</p>
        <p>e)其他危害计算机信息网络安全的行为。</p>
        <p>4）不以任何方式干扰“久来米”平台的服务。</p>
        <p>5）遵守“久来米”平台的所有其他规定和程序。</p>
        <p>
          您需对自己在使用“久来米”平台服务过程中的行为承担法律责任。您理解，如果“久来米”平台发现其网站传输的信息明显属于上段第(1)条所列内容之一，依据中国法律，“久来米”平台有义务立即停止传输，保存有关记录，向国家有关机关报告，并且删除含有该内容的地址、目录或关闭服务器。
        </p>
        <p>
          您使用“久来米”平台电子公告服务，包括电子布告牌、电子白板、电子论坛、网络聊天室和留言板等以交互形式为上网用户提供信息发布条件的行为，也须遵守本条的规定以及“久来米”平台将专门发布的电子公告服务规则，上段中描述的法律后果和法律责任同样适用于电子公告服务的用户。
        </p>
        <p>
          若您的行为不符合以上提到的服务条款，“久来米”平台将作出独立判断立即取消用户服务帐号。
        </p>
        <p>五、特别授权</p>
        <p>您完全理解并不可撤销地授予“久来米”平台及其关联公司下列权利：</p>
        <p>
          1、您完全理解并不可撤销地授权“久来米”平台或“久来米”平台授权的第三方或您与“久来米”平台一致同意的第三方，根据本协议及“久来米”平台规则的规定，处理您在“久来米”平台上发生的所有交易及可能产生的交易纠纷。您同意接受“久来米”平台或“久来米”平台授权的第三方或您与“久来米”平台一致同意的第三方的判断和调查处理决定。该决定将对你具有法律约束力。
        </p>
        <p>
          2、一旦您向“久来米”平台和其关联公司做出任何形式的承诺，且相关公司已确认您违反了该承诺，则“久来米”平台有权立即按您的承诺或协议约定的方式对您的账户采取限制措施，包括中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，“久来米”平台无须就相关确认与您核对事实，或另行征得您的同意，且“久来米”平台无须就此限制措施或公示行为向你承担任何的责任。
        </p>
        <p>
          3、对于您提供的资料及数据信息，您授予“久来米”平台及其关联公司永久免费的许可使用权利(并有权在多个层面对该权利进行再授权)。此外，“久来米”平台及其关联公司有权(全部或部分)使用、复制、修订、改写、发布、翻译、分发、执行和展示您的全部资料数据（包括但不限于注册资料、交易行为数据及全部展示于“久来米”平台的各类信息）或制作其派生作品，并以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。
        </p>
        <p>六、责任范围和责任限制</p>
        <p>
          1、“久来米”平台负责按"现状"和"可得到"的状态向您提供“久来米”平台服务。但“久来米”平台对“久来米”平台服务不作任何明示或暗示的保证，包括但不限于“久来米”平台服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，“久来米”平台也不对“久来米”平台服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、稳定性和及时性做出任何承诺和保证。
        </p>
        <p>
          2、您了解“久来米”平台仅作为您获取需求和服务信息、物色交易对象、就服务的交易进行协商及开展交易的场所，平台上的服务和需求信息系由用户自行发布，且可能存在风险和瑕疵，“久来米”平台无法完全控制交易所涉及的服务的质量、安全或合法性，信息的真实性或准确性，以及交易各方履行其在贸易协议中各项义务的能力。您应自行谨慎判断确定相关信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。
        </p>
        <p>
          3、除非法律法规明确要求，或出现以下情况，否则，“久来米”平台没有义务对所有用户的信息数据、服务信息、交易行为以及与交易有关的其它事项进行事先审查：
        </p>
        <p>
          1）“久来米”平台有合理的理由认为特定会员及具体交易事项可能存在重大违法或违约情形。
        </p>
        <p>
          2）“久来米”平台有合理的理由认为用户在“久来米”平台的行为涉嫌违法或其他不当。
        </p>
        <p>
          4、“久来米”平台或“久来米”平台授权的第三方或您与“久来米”平台一致同意的第三方有权基于您不可撤销的授权受理您与其他会员因交易产生的争议，并有权单方判断与该争议相关的事实及应适用的规则，进而做出处理决定，包括但不限于调整相关订单的交易状态，争议款项的全部或部分支付给交易一方或双方。该处理决定对您有约束力。如你未在限期内执行处理决定的，则“久来米”平台有权利（但无义务）直接使用您账户内的款项，或您向“久来米”平台及其关联公司交纳的保证金代为支付。您应及时补足保证金并弥补“久来米”平台及其关联公司的损失，否则“久来米”平台及其关联公司有权直接抵减您在其它合同项下的权益，并有权继续追偿。
        </p>
        <p>
          您理解并同意，“久来米”平台或“久来米”平台授权的第三方或您与“久来米”平台一致同意的第三方并非司法机构，仅能以普通人的身份对证据进行鉴别，“久来米”平台或“久来米”平台授权的第三方或您与“久来米”平台一致同意的第三方对争议的调查处理完全是基于您不可撤销的授权，其无法保证争议处理结果符合您的期望，也不对争议调处结论承担任何责任。如你因此遭受损失，您同意自行向受益人索偿。
        </p>
        <p>
          5、您了解并同意，“久来米”平台不对因下述任一情况而导致您的任何损害赔偿承担责任，包括但不限于利润、商誉、数据等方面的损失或其它无形损失的损害赔偿(无论“久来米”平台是否已被告知该等损害赔偿的可能性)：
        </p>
        <p>1）使用或未能使用“久来米”平台服务。</p>
        <p>2）第三方未经批准的使用您的账户或更改您的数据。</p>
        <p>
          3）通过“久来米”平台交易服务或获取任何平台增值服务、数据、信息或进行交易等行为或替代行为产生的费用及损失。
        </p>
        <p>4）您对“久来米”平台服务的误解。</p>
        <p>
          5）任何非因“久来米”平台的原因而引起的与“久来米”平台服务有关的其它损失。
        </p>
        <p>
          6、不论在何种情况下，“久来米”平台均不对由于信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
        </p>
        <p>七、协议终止</p>
        <p>
          1、您同意，“久来米”平台有权自行全权决定以任何理由不经事先通知的中止、终止向您提供部分或全部“久来米”平台服务，暂时冻结或永久冻结（注销）您的账户在“久来米”平台的权限，且无须为此向您或任何第三方承担任何责任。
        </p>
        <p>
          2、出现以下情况时，“久来米”平台有权直接以注销账户的方式终止本协议，并有权永久冻结（注销）您的账户在“久来米”平台网的权限和收回账户对应的“久来米”平台昵称：
        </p>
        <p>
          1）“久来米”平台终止向您提供服务后，您涉嫌再一次直接或间接以他人名义注册为“久来米”平台用户的；
        </p>
        <p>
          2）您提供的电子邮箱不存在或无法接收电子邮件，且没有任何其他方式可以与您进行联系，或“久来米”平台以其它联系方式通知您更改电子邮件信息，而您在“久来米”平台通知后三个工作日内仍未更改为有效的电子邮箱的。
        </p>
        <p>3）您提供的用户信息中的主要内容不真实或不准确或不及时或不完整；</p>
        <p>
          4）本协议（含规则）变更时，您明示并通知“久来米”平台不愿接受新的服务协议的；
        </p>
        <p>5）其它“久来米”平台认为应当终止服务的情况。</p>
        <p>
          6）您若反对任何服务条款的建议或对后来的条款修改有异议，或对“久来米”平台服务不满，您只有以下的追索权：
        </p>
        <p>a）不再使用“久来米”平台服务。</p>
        <p>b）结束用户使用“久来米”平台服务的资格。</p>
        <p>c）通告“久来米”平台停止该用户的服务。</p>
        <p>
          结束用户服务后，即协议终止。您使用“久来米”平台服务的权利马上中止。从那时起，“久来米”平台不再对您承担任何义务。
        </p>
        <p>
          3、您的账户服务被终止或者账户在“久来米”平台的权限被永久冻结（注销）后，“久来米”平台没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。
        </p>
        <p>
          4、您同意，您与“久来米”平台的合同关系终止后，“久来米”平台仍享有下列权利：
        </p>
        <p>
          1）继续保存您的用户信息及您使用“久来米”平台服务期间的所有交易信息。
        </p>
        <p>
          2）您在使用“久来米”平台服务期间存在违法行为或违反本协议和/或规则的行为的，“久来米”平台仍可依据本协议向您主张权利。
        </p>
        <p>
          5、“久来米”平台中止或终止向您提供“久来米”平台服务后，对于您在服务中止或终止之前的交易行为依下列原则处理，您应独力处理并完全承担进行以下处理所产生的任何争议、损失或增加的任何费用，并应确保“久来米”平台免于因此产生任何损失或承担任何费用：
        </p>
        <p>
          1）您在服务中止或终止之前已经上传至“久来米”平台的服务交易尚未交易的，“久来米”平台有权在中止或终止服务的同时删除此项服务交易的相关信息；
        </p>
        <p>
          2）您在服务中止或终止之前已经与其他会员达成服务交易合同，但合同尚未实际履行的，“久来米”平台有权删除该买卖合同及其交易服务的相关信息；
        </p>
        <p>
          3）您在服务中止或终止之前已经与其他会员达成买卖合同且已部分履行的，“久来米”平台可以不删除该项交易，但“久来米”平台网有权在中止或终止服务的同时将相关情形通知您的交易对方。
        </p>
        <p>八、隐私权政策</p>
        <p>
          “久来米”平台将在“久来米”平台公布并不时修订隐私权政策，隐私权政策构成本协议的有效组成部分。
        </p>
        <p>九、知识产权条款</p>
        <p>
          1、本网站之域名、商标，以及本网站内所有信息内容（本网站用户发布的信息除外），包括但不限于网站架构、文字、图片、软件、音频、视频、用户等级/评价体系/信用体系/榜单等数据信息，以及所有本网站使用之所有技术、数据分析模型、计算机软件、数据库（第三方创建并保留所有权和/或知识产权的除外），其所有权知识产权均归属“久来米”平台唯一所有，未经“久来米”平台书面许可，任何人均不得擅自进行全部和局部复制、转载、引用和链接等使用和处分。
        </p>
        <p>
          2、用户在本网站发布的任何信息和内容（用户隐私信息除外），包括但不限于文字、图片、软件、音频、视频等，均被视为永久、免费且不可撤销地许可“久来米”平台自行或许可其关联方、业务合作方、宣传方不受任何限制的使用和处分。
        </p>
        <p>
          3、任何单位或者个人不得以任何方式引诱本网站用户或者第三方复制转载本网站之信息内容，或者同意该用户或者第三方复制转载本网站内容。
        </p>
        <p>
          4、任何违反本站知识产权声明的行为，本站保留进一步追究法律责任的权利。
        </p>
        <p>十、法律适用、管辖与其他</p>
        <p>
          1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，如无相关法律规定的，则应参照通用国际商业惯例或行业惯例。
        </p>
        <p>
          2、因本协议产生之争议需根据您使用的服务归属的平台确定具体的争议对象，因您使用“久来米”平台服务所产生的争议应由“久来米”平台的经营者与您直接沟通并处理。一旦产生不可调和的争议，您与“久来米”平台的经营者均同意该争议统一归“久来米”平台平台所在地相应级别的法院管辖。
        </p>
        <p>3、本协议以其中文版本为最终生效版本。</p>
        <p>签署声明：</p>
        <p>
          您已经充分知悉且已理解本协议全部内容，保证网络流程操作者已获有效授权并足以代表自己或者授权人订立本协议。
        </p>
      </div>
    </template>
    <template v-else>
      <div v-if="type == 'privacy'" id="content" class="content">
        <p class="title">“大麦灵工”平台隐私政策协议</p>
        <p><b>【特别提示】</b></p>
        <p>
          本政策仅适用于【上海承服企业管理服务外包】（以下简称“承服”）提供的产品和服务及其延伸的功能（以下简称“承服服务”），包括“大麦灵工”平台、网站、H5、小程序向您提供的各项产品和服务。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用，该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。
        </p>
        <p>
          请仔细阅读《隐私政策》（尤其是粗体内容）并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可通过《隐私政策》中的联系方式咨询我们。
        </p>
        <p><b>引言</b></p>
        <p>
          欢迎您使用承服服务！我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《隐私政策》（“本隐私政策”）向您说明在您使用我们的产品与/或服务时我们如何收集、使用、保存、共享和转移这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
        </p>
        <p>
          本隐私政策与您所使用的承服服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。
        </p>
        <p><b>一、我们如何收集和使用您的个人信息</b><b></b></p>
        <p>
          个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
        </p>
        <p>
          我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其他用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。
        </p>
        <p><b>（一）您须授权我们收集和使用您个人信息的情形</b><b></b></p>
        <p>
          我们的产品与/或服务包括一些核心功能，这些功能包含了税务代理服务的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：
        </p>
        <p>1、税务代理服务所必须的功能</p>
        <p>（1）账号注册、登录与认证</p>
        <p>
          当您使用“大麦灵工”平台线上税务代理服务系统时，你需要注册“大麦灵工”平台账号。当您注册“大麦灵工”平台账号时，您至少需要向我们提供您准备使用的“大麦灵工”平台账号名称、密码、手机号码，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。您的账号名为您的默认昵称，您可以修改和补充您的昵称、性别、生日以及您的实名认证相关信息，这些信息均属于您的“账号信息”。您补充的账号信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，将会影响您使用税务代理服务的基本功能。
        </p>
        <p>
          当您成功注册“大麦灵工”平台账号后，可以使用“大麦灵工”平台账号登录““大麦灵工”平台”，登录时您需要向我们提供您的“大麦灵工”平台账号信息。若您不提供这类信息，您将无法登录“大麦灵工”平台，此时您为开展税务代理服务使用浏览和搜索功能，我们将不会收集您的上述个人信息。
        </p>
        <p>
          您授权“大麦灵工”平台将您的个人信息发送给您指定的服务商。服务商再通过“大麦灵工”平台将您的服务外包发送给服务需求方。
        </p>
        <p>（2）下单及订单管理</p>
        <p>
          当您指定的服务商与需求方达成一致意向时，您应根据订单要求向需求方提供服务。您需要在订单中至少填写您的姓名、邮寄地址或电子邮箱以及手机号码，同时该订单中会载明订单号、下单时间、服务内容、服务费。
        </p>
        <p>（3）交付产品、支付功能</p>
        <p>
          因您将所提供的服务转包给服务商，服务商将您的服务提供给需求方后，需求方应向服务商支付服务费，服务商再将所收取的服务费通过银行转账汇至您的银行账户。
        </p>
        <p>（4）客服与售后功能</p>
        <p>
          我们的电话客服和售后功能会使用您的账号信息和订单信息。为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更服务地址、联系人或联系电话。
        </p>
        <p>2、保障交易安全所必须的功能</p>
        <p>
          为保障您使用我们的产品与/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、设备序列号、操作系统及软件版本、设备状态、网络状况）、历史上网记录、日志信息、面部信息、IP地址、位置信息、浏览信息、订单信息、wifi信息、IMSI、SIM卡信息、电池使用情况来识别是否为真实自然人用户、是否为异常地点登录、判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对承服系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用承服服务过程中对您的服务及账号安全进行保护。
        </p>
        <p><b>（二）您个人信息使用的规则</b><b></b></p>
        <p>
          1、我们会根据本隐私政策的内容，为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
        </p>
        <p>
          2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。
        </p>
        <p>
          3、当我们展示您的个人信息时，我们会采用包括去标识化或者匿名化处理方式对您的信息进行脱敏，以保护您的信息安全。
        </p>
        <p>
          4、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的明示同意。
        </p>
        <p>
          请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。
        </p>
        <p><b>二、我们如何对外提供您的个人信息</b><b></b></p>
        <p><b>（一）共享</b><b></b></p>
        <p>
          1、我们不会与承服以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
        </p>
        <p>（1）事先获得您明确的同意或授权；</p>
        <p>（2）根据法律法规、行政及司法部门强制性要求进行提供；</p>
        <p>
          （3）在法律法规允许的范围内，为维护承服或合作伙伴、您或其他承服用户或社会公众利益、财产或安全免遭损害而有必要提供；
        </p>
        <p>
          （4）只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
        </p>
        <p>（5）应您需求为您处理您与他人的纠纷或争议；</p>
        <p>
          （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
        </p>
        <p>（7）基于学术研究而使用；</p>
        <p>（8）基于符合法律法规的社会公共利益、突发公共卫生事件而使用。</p>
        <p>
          2、为向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账号与交易安全，我们可能会将您的个人信息与我们的合作伙伴共享，共享内容及目的。我们的关联方或者合作伙伴如要改变个人信息的处理目的，将再次征求您的授权同意。
        </p>
        <p>
          请您注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您共享时谨慎考虑并决定。
        </p>
        <p><b>（二）委托处理</b><b></b></p>
        <p>
          我们可能委托授权合作伙伴处理您的个人信息，以便向您提供相应的产品或服务。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供产品或服务所必要的个人信息。如果我们的合作伙伴将您的个人信息用于我们未委托的用途，其将单独征得您的同意。
        </p>
        <p>我们的合作伙伴包括以下类型：</p>
        <p>
          （1）提供技术服务的供应商。我们可能会将您的个人信息提供给支持我们功能的第三方，包括为我们提供基础设施技术服务、数据分析服务及数据处理服务等的供应商。
        </p>
        <p>
          （2）广告、分析服务类合作伙伴。我们会遵守法律法规及行业通用安全技术，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。
        </p>
        <p>
          对我们委托合作伙伴处理个人信息的，我们会与其签署数据保护协议，要求其按照协议约定、本隐私政策以及相关法律法规要求来处理个人信息。
        </p>
        <p><b>（三）转移</b><b></b></p>
        <p>
          在因合并、分立、解散、被宣告破产等原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式，要求接收您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
        </p>
        <p><b>（四）公开披露</b><b></b></p>
        <p>
          我们仅会在以下情况，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
        </p>
        <p>（1）根据您单独同意的授权范围披露相应个人信息；</p>
        <p>
          （2）如果您严重违反法律法规或者相关协议、规则，我们可能会披露您的相关违规行为及我们对您采取的措施。
        </p>
        <p><b>三、我们如何保护和保存您的个人信息</b><b></b></p>
        <p><b>（一）我们保护您个人信息的技术与措施</b><b></b></p>
        <p>
          我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
        </p>
        <p>1、数据安全技术措施</p>
        <p>
          我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
        </p>
        <p>2、为保护个人信息采取的其他安全措施</p>
        <p>
          通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用；通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制；建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
        </p>
        <p>
          互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。在通过承服与第三方进行网上产品与/或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
        </p>
        <p>3、安全事件处置</p>
        <p>
          为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
        </p>
        <p>
          一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时，我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        </p>
        <p>
          如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账号及密码发生泄露，请您立即通过本隐私政策“七、如何联系我们”中明确的联系方式联络我们，以便我们采取相应措施。
        </p>
        <p><b>（二）您个人信息的保存</b><b></b></p>
        <p>1、您的个人信息将存储于中华人民共和国境内。</p>
        <p>
          2、在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
        </p>
        <p>
          3、请您注意，当您成功注销“大麦灵工”平台账号后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
        </p>
        <p>
          4、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
        </p>
        <p><b>四、您如何管理您的个人信息</b><b></b></p>
        <p>
          承服非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
        </p>
        <p>1、访问和更正您的个人信息</p>
        <p>
          除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括（1）您的账号信息；（2）您的收件信息；（3）您的订单信息（4）您的浏览信息；（5）您的评论信息；（6）您的发票信息；（7）您的档案信息。
        </p>
        <p>
          对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
        </p>
        <p>2、删除您的个人信息</p>
        <p>
          您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。
        </p>
        <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
        <p>（1）如果我们处理个人信息的行为违反法律法规；</p>
        <p>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
        <p>（3）如果我们处理个人信息的行为严重违反了与您的约定；</p>
        <p>
          （4）如果您不再使用我们的产品或服务，或您注销了“大麦灵工”平台账号；
        </p>
        <p>（5）如果我们终止服务及运营，或者保存期限已届满。</p>
        <p>
          若我们决定响应您的删除请求，我们还将同时尽可能通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体已获得您的独立授权。
        </p>
        <p>
          请您注意，当您成功注销“大麦灵工”平台账号后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到可以删除或实现匿名化。
        </p>
        <p>3、改变您授权同意的范围或撤回您的授权</p>
        <p>
          您可以通过删除信息、关闭设备功能、在承服网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账号的方式，撤回我们继续收集您个人信息的全部授权。
        </p>
        <p>
          请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
        </p>
        <p>4、注销账号</p>
        <p>
          您可以在我们的产品中直接申请注销账号。您注销账号后，我们将停止为您提供产品与/或服务，并根据适用法律的要求删除您的个人信息，或进行匿名化处理。
        </p>
        <p>5、获取您的个人信息副本</p>
        <p>
          您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您随时联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供相应的个人信息副本。
        </p>
        <p>6、响应您的请求</p>
        <p>
          如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息或者获取个人信息副本，或您认为承服存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以发送电子邮件至
          &nbsp;service@shchengfu.com.cn或通过本隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
        </p>
        <p><b>五、未成年人的个人信息保护</b><b></b></p>
        <p>
          1、非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您监护人的同意。承服根据国家相关法律法规的规定保护未成年人的个人信息。
        </p>
        <p>
          2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
        </p>
        <p>
          3、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
        </p>
        <p><b>六、通知和修订</b><b></b></p>
        <p>
          1、为给您提供更好的服务以及随着承服业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在“大麦灵工”网站、“大麦灵工”移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新。
        </p>
        <p>
          2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
        </p>
        <p>本隐私政策所指的重大变更包括但不限于：</p>
        <p>
          （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        </p>
        <p>
          （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
        </p>
        <p>（3）个人信息共享、转移或公开披露的主要对象发生变化；</p>
        <p>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
        <p>
          （5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
        </p>
        <p>（6）个人信息安全影响评估报告表明存在高风险时。</p>
        <p>
          3为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。
        </p>
        <p><b>七、如何联系我们</b><b></b></p>
        <p>
          1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。
        </p>
        <p>个人信息保护负责人邮箱：service@shchengfu.com.cn</p>
        <p>
          联系地址：上海浦东新区上川路1501号、北京市朝阳区霄云路国航大厦7D
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </p>
        <p>
          2、一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
        </p>
        <p>
          附：个人信息及敏感个人信息示例个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。（本定义出自于《中华人民共和国个人信息保护法》）。
        </p>
      </div>
      <div v-else id="content" class="content">
        <p class="title">“大麦”平台注册服务协议</p>
        <p>亲爱的用户</p>
        <p style="text-indent: 21pt">
          欢迎您（即本协议项下的“用户”）与上海承服企业管理服务外包有限公司签署《大麦灵工平台注册服务协议》（以下简称“本协议”），感谢您选用“大麦灵工”品牌，通过“大麦灵工”平台/软件、互联网及线下向您提供的各项服务。
        </p>
        <p style="text-indent: 21pt">
          您访问本网站、使用本网站的任何服务、在本网站内与其他用户的交流互动、在本网站内发布任何信息或内容以及使用其他用户发布的任何信息或内容等一切与本网站有关的活动和行为，均受到以下协议条款的约束和限制，请您务必仔细阅读。&nbsp;特别是采用粗体字标注的免除或限制责任的条款、法律使用和争议解决条款。
        </p>
        <p style="text-indent: 21pt">
          【特别提示】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，或您以其他“大麦灵工”允许的方式实际使用“大麦灵工”平台服务时，即表示您已充分阅读、理解并接受本协议的全部内容，并与“大麦灵工”平台达成协议。您承诺接受并遵守本协议的所有约定，届时您不应以未阅读本协议的内容或者未获得大麦灵工对您问询的解答等理由，主张本协议无效，或要求撤销本协议。本网站有权随时变更本协议并在本网站上予以公告。经修订的条款一经在本网站公布后，立即自动生效。如您不同意相关变更，必须停止使用本网站。若您对本协议的条款或其他变更内容，存在任何疑问，请您立刻联系大麦灵工平台工作人员进行咨询答疑，若您不同意本协议，您应立即停止注册或停止使用大麦灵工平台以及平台服务。
        </p>
        <p>一、协议范围</p>
        <p>
          1、本协议由您和“大麦灵工”平台（域名为“https://wap.cehngfu.com.cn/”、“大麦灵工微信小程序”或其他H5等开放平台,以下简称“大麦灵工”平台）运营方上海承服企业管理服务外包有限公司订立，本协议具有合同效力。
        </p>
        <p>
          2、除另行明确声明外，“大麦灵工”平台服务包含任何“大麦灵工”平台及其关联公司、第三方服务商提供的基于互联网以及移动互联网的相关服务，且均受本协议约束。如果您不同意本协议的约定，您应立即停止注册或停止使用“大麦灵工”平台服务。
        </p>
        <p>
          3、本协议内容包括协议正文、法律声明、《“大麦灵工”平台服务规则》及所有“大麦灵工”平台已经发布或将来可能发布的各类规则、公告或通知（以下合称“‘大麦灵工’平台规则”或“规则”）。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。
        </p>
        <p>
          4、“大麦灵工”平台有权依据国家法律法规调整的要求和公平交易的商业原则及时地制订、修改本协议及各类规则，并以网站公示的方式进行变更公告，无需另行单独通知您。变更后的协议和规则一经在网站公布后，立即或在公告明确的特定时间自动生效。若您在前述变更公告后继续使用“大麦灵工”平台服务的，即表示您已经阅读、理解并接受经修订的协议和规则。若您不同意相关变更，应当立即停止使用“大麦灵工”平台服务。
        </p>
        <p>二、注册与账户</p>
        <p>1、主体资格</p>
        <p>
          您确认，在您完成注册程序或以其他“大麦灵工”平台允许的方式实际使用“大麦灵工”平台服务时，您应当符合下列条件之一:
        </p>
        <p>1）年满十八周岁，并具有完全民事权利能力和民事行为能力的自然人；</p>
        <p>
          2）无民事行为能力人或限制民事行为能力人应在其监护人的监护下使用“大麦灵工”平台服务；
        </p>
        <p>
          3）根据中国法律、法规、规章等强制性法律规定成立并合法存在的机关、企事业单位、个体工商户、社团组织和其他组织。
        </p>
        <p>2、用户的帐号、密码和安全性</p>
        <p>
          您一旦注册成功成为用户，您将得到一个密码和帐号。如果您未保管好自己的帐号和密码而对您、“大麦灵工”平台或第三方造成损害，您将负全部责任。另外，每个用户都要对其帐户中发生的所有活动和事件负全责。您可随时改变您的密码和图标，也可以结束旧的帐户重开一个新帐户。用户同意若发现任何非法使用用户帐号或存在安全漏洞的情况，立即通告“大麦灵工”平台。
        </p>
        <p>三、“大麦灵工”平台服务</p>
        <p>
          1、您可以在“大麦灵工”平台上发布需求/服务、查询需求/服务、达成交易意向并进行交易、对其他会员进行评价、参加“大麦灵工”平台组织的活动以及使用其它信息服务及技术服务，具体以所开通的平台提供的服务内容为准。
        </p>
        <p>
          2、您在“大麦灵工”平台上交易过程中与其他会员发生交易纠纷时，一旦您或其它会员任一方或双方共同提交“大麦灵工”平台要求调查处理，则“大麦灵工”平台作为独立第三方，有权根据实际调查情况做出处理决定；若您不接受“大麦灵工”平台的处理决定，您可以通过法律规定的其他途径解决您与其他会员的有关纠纷。
        </p>
        <p>
          3、您了解并同意，“大麦灵工”平台有权应相关部门（包括司法部门及行政部门）的要求，向其提供您向“大麦灵工”平台提供的用户信息和交易记录等必要信息。如您涉嫌侵犯他人知识产权或其他合法权益，则“大麦灵工”平台亦有权在初步判断涉嫌侵权行为存在的情况下，向权利人提供您必要的身份信息。
        </p>
        <p>
          4、您应当自行承担因交易产生或取得的相关费用，并依法纳税，平台不承担代扣代缴义务。
        </p>
        <p>5、拒绝提供担保和免责声明</p>
        <p>
          您明确同意使用“大麦灵工”平台服务的风险由您个人承担。平台是免费提供服务的，故“大麦灵工”平台明确表示不提供任何类型的担保，不论是明确的或隐含的，但是对商业性的隐含担保、特定目的和不违反规定的适当担保除外。“大麦灵工”平台不担保服务一定能满足用户的全部要求，也不担保服务任何情况下均不会中断；对服务的及时性、安全性、真实性、准确性以及信息能否准确、及时、顺利地传送，均不作保证。用户理解并接受下载或通过“大麦灵工”平台产品服务获取何种信息资料取决于用户自己，并由其承担系统受损、资料丢失以及其它有关风险。“大麦灵工”平台对在服务网上得到的任何商品购物服务、交易进程、招聘信息，承包过程、成果审核等都不作担保。用户不会从“大麦灵工”平台收到口头或书面的意见或信息。
        </p>
        <p>四、“大麦灵工”平台服务使用规范</p>
        <p>
          1、在“大麦灵工”平台上使用“大麦灵工”平台服务过程中，你承诺遵守以下约定：
        </p>
        <p>
          1）实施的所有行为均遵守国家法律、法规等规范性文件及“大麦灵工”平台各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不偷逃应缴税费，不违反本协议及相关规则。
        </p>
        <p>
          2）在与其他会员交易过程中，遵守诚实信用原则，不采取不正当竞争行为，不扰乱网上交易的正常秩序，不从事与网上交易无关的行为。
        </p>
        <p>
          3）不发布国家禁止销售的或限制销售的服务信息（除非取得合法且足够的许可），不发布涉嫌侵犯他人知识产权或其它合法权益的服务信息，不发布违背社会公共利益或公共道德或“大麦灵工”平台认为不适合在“大麦灵工”平台上销售的服务信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。
        </p>
        <p>
          4）不采取不正当手段（包括但不限于虚假需求、互换好评等方式）提高自身或他人评价，或采用不正当手段恶意评价其他用户，降低其他人评价。
        </p>
        <p>
          5）未经公司书面许可，不对“大麦灵工”平台上的任何数据作商业性利用，包括但不仅限于在未经“大麦灵工”平台事先书面同意的情况下，以复制、传播等其他方式使用“大麦灵工”平台站上展示的资料。
        </p>
        <p>
          6）不使用任何装置、软件或例行程序干预或试图干预“大麦灵工”平台的正常运作或正在“大麦灵工”平台上进行的任何交易、活动。您不得采取任何将导致不合理的庞大数据负载加诸“大麦灵工”平台网络设备的行为。
        </p>
        <p>2、您了解并同意：</p>
        <p>
          1）您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保“大麦灵工”平台免于因此产生任何损失或支出费用。
        </p>
        <p>
          2）基于维护“大麦灵工”平台交易秩序及交易安全的需要，“大麦灵工”平台有权在发生恶意交易等扰乱市场正常交易秩序的情形下，执行强制关闭相应交易订单等操作。
        </p>
        <p>
          3）&nbsp;经国家行政或司法机关的生效法律文书确认您存在违法或侵权行为，或者“大麦灵工”平台根据自身的判断，认为您的行为涉嫌违反法律法规的规定或涉嫌违反本协议和/或规则的条款的，则“大麦灵工”平台方有权在“大麦灵工”平台上公示您的该等涉嫌违法或违约行为及“大麦灵工”平台已对您采取的措施。
        </p>
        <p>
          4）对于您在“大麦灵工”平台上发布的涉嫌违法、涉嫌侵犯他人合法权利或违反本协议和规则的信息，“大麦灵工”平台有权不经通知你即予以删除，且按照规则的规定进行处罚。
        </p>
        <p>
          5）对于您违反本协议项下承诺，或您在“大麦灵工”平台上实施的行为，包括您未在“大麦灵工”平台上实施但已经对“大麦灵工”平台及其用户产生不良影响的行为，“大麦灵工”平台有权单方认定您行为的性质及是否构成对本协议规则的违反，并根据单方认定结果适用规则予以处理或终止向您提供服务，且无须征得您的同意或提前通知予您。您应自行保存与您行为有关的全部证据，并应对无法提供充要证据而承担的不利后果。
        </p>
        <p>
          6）如您涉嫌违反有关法律或者本协议之规定，使“大麦灵工”平台遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿“大麦灵工”平台因此造成的损失发生的费用，包括合理的律师费用。
        </p>
        <p>3、您了解并同意：</p>
        <p>
          您单独承担发布内容的责任。您对服务的使用是根据所有适用于服务的地方法律、国家法律和国际法律标准的。您承诺:
        </p>
        <p>
          1）在“大麦灵工”平台的网页上发布信息或者利用“大麦灵工”平台的服务时必须符合中国有关法规，不得在“大麦灵工”平台的网页上或者利用“大麦灵工”平台的服务制作、复制、发布、传播以下信息:
        </p>
        <p style="">a)反对宪法所确定的基本原则的;</p>
        <p style="">
          b)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
        </p>
        <p style="">c)损害国家荣誉和利益的；</p>
        <p style="">d)煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p style="">e)破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p style="">f)散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p style="">g)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p style="">h)侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p style="">i)含有法律、行政法规禁止的其他内容的。</p>
        <p>
          2）在“大麦灵工”平台的网页上发布信息或者利用“大麦灵工”平台的服务时还必须符合其他有关国家和地区的法律规定以及国际法的有关规定。
        </p>
        <p>3）不利用“大麦灵工”平台的服务从事以下活动:</p>
        <p style="">
          a)未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
        </p>
        <p style="">
          b)未经允许，对计算机信息网络功能进行删除、修改或者增加的；
        </p>
        <p style="">
          c)未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
        </p>
        <p style="">d)故意制作、传播计算机病毒等破坏性程序的；</p>
        <p style="">e)其他危害计算机信息网络安全的行为。</p>
        <p>4）不以任何方式干扰“大麦灵工”平台的服务。</p>
        <p>5）遵守“大麦灵工”平台的所有其他规定和程序。</p>
        <p>
          您需对自己在使用“大麦灵工”平台服务过程中的行为承担法律责任。您理解，如果“大麦灵工”平台发现在其网站传输的信息明显属于上段第(1)条所列内容之一，依据中国法律，“大麦灵工”平台有义务立即停止传输，保存有关记录，向国家有关机关报告，并且删除含有该内容的地址、目录或关闭服务器。
        </p>
        <p>
          您使用“大麦灵工”平台电子公告服务，包括电子布告牌、电子白板、电子论坛、网络聊天室和留言板等以交互形式为上网用户提供信息发布条件的行为，也须遵守本条的规定以及“大麦灵工”平台将专门发布的电子公告服务规则，上段中描述的法律后果和法律责任同样适用于电子公告服务的用户。
        </p>
        <p>
          若您的行为不符合以上提到的服务条款，“大麦灵工”平台将作出独立判断并立即取消用户服务帐号。
        </p>
        <p>五、特别授权</p>
        <p>您完全知晓并理解“大麦灵工”平台及其关联公司有下列权利：</p>
        <p>
          1、若经您授权，“大麦灵工”平台或“大麦灵工”平台授权的第三方或您与“大麦灵工”平台一致同意的第三方，有权根据本协议及“大麦灵工”平台规则的规定，处理您在“大麦灵工”平台上发生的所有交易及可能产生的交易纠纷。您若同意接受“大麦灵工”平台或“大麦灵工”平台授权的第三方或您与“大麦灵工”平台一致同意的第三方根绝实际调查情况做出的处理决定，则该决定将对您具有法律约束力。
        </p>
        <p>
          2、一旦您向“大麦灵工”平台和其关联公司做出任何形式的承诺，且相关公司已确认您违反了该承诺，则“大麦灵工”平台有权立即按您的承诺或协议约定的方式对您的账户采取限制措施，包括中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，“大麦灵工”平台无须就相关确认与您核对事实，或另行征得您的同意，且“大麦灵工”平台无须就此限制措施或公示行为向你承担任何的责任。
        </p>
        <p>
          3、若您未另行书面告知，则对于您提供的资料及数据信息，视为您授予“大麦灵工”平台及其关联公司永久免费的使用权(并有权在多个层面对该权利进行再授权)。此外，“大麦灵工”平台及其关联公司有权为合法目的、以合法方式(全部或部分)使用、复制、修订、改写、发布、翻译、分发、执行和展示您提供的全部资料数据（包括但不限于注册资料、交易行为数据及全部展示于“大麦灵工”平台的各类信息）或制作其派生作品，并以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。
        </p>
        <p>六、责任范围和责任限制</p>
        <p>
          1、“大麦灵工”平台负责按"现状"和"可得到"的状态向您提供“大麦灵工”平台服务。但“大麦灵工”平台对“大麦灵工”平台服务不作任何明示或暗示的保证，包括但不限于“大麦灵工”平台服务的适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。同时，“大麦灵工”平台也不对“大麦灵工”平台服务所涉及的技术及信息的有效性、准确性、正确性、可靠性、稳定性和及时性做出任何承诺和保证。
        </p>
        <p>
          2、您了解“大麦灵工”平台仅作为您获取需求和服务信息、物色交易对象、就服务的交易进行协商及开展交易的场所，平台上的服务和需求信息系由用户自行发布，且可能存在风险和瑕疵，“大麦灵工”平台无法完全控制交易所涉及的服务的质量、安全或合法性，信息的真实性或准确性，以及交易各方履行其在贸易协议中各项义务的能力。您应自行谨慎判断确定相关信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。
        </p>
        <p>
          3、除非法律法规明确要求，或出现以下情况，否则，“大麦灵工”平台没有义务对所有用户的信息数据、服务信息、交易行为以及与交易有关的其它事项进行事先审查：
        </p>
        <p>
          1）“大麦灵工”平台有合理的理由认为特定会员及具体交易事项可能存在重大违法或违约情形。
        </p>
        <p>
          2）“大麦灵工”平台有合理的理由认为用户在“大麦灵工”平台的行为涉嫌违法或其他不当。
        </p>
        <p>
          4、“大麦灵工”平台或“大麦灵工”平台授权的第三方或您与“大麦灵工”平台一致同意的第三方有权基于您的授权受理您与其他会员因交易产生的争议，并有权根据实际调查情况单方判断与该争议相关的事实情况及应适用的规则，进而做出处理决定，包括但不限于调整相关订单的交易状态，争议款项的全部或部分支付给交易一方或双方。若您未书面提出反对意见，则该处理决定对您有约束力。如你未在限期内执行处理决定的，则“大麦灵工”平台有权利（但无义务）直接使用您账户内的款项，或您向“大麦灵工”平台及其关联公司交纳的保证金代为支付。您应及时补足保证金并弥补“大麦灵工”平台及其关联公司的损失，否则“大麦灵工”平台及其关联公司有权直接抵减您在其它合同项下的权益，并有权继续追偿。
        </p>
        <p>
          您理解并同意，“大麦灵工”平台或“大麦灵工”平台授权的第三方或您与“大麦灵工”平台一致同意的第三方并非司法机构，仅能以普通人的身份对证据进行鉴别，“大麦灵工”平台或“大麦灵工”平台授权的第三方或您与“大麦灵工”平台一致同意的第三方对争议的调查处理完全是基于您的授权，其无法保证争议处理结果符合您的期望，也不对争议调处结论承担任何责任。如你因此遭受损失，您同意自行向受益人索偿。
        </p>
        <p>
          5、您了解并同意，“大麦灵工”平台不对因下述任一情况而导致您的任何损害赔偿承担责任，包括但不限于利润、商誉、数据等方面的损失或其它无形损失的损害赔偿(无论“大麦灵工”平台是否已被告知该等损害赔偿的可能性)：
        </p>
        <p>1）使用或未能使用“大麦灵工”平台服务。</p>
        <p>2）第三方未经批准的使用您的账户或更改您的数据。</p>
        <p>
          3）通过“大麦灵工”平台交易服务或获取任何平台增值服务、数据、信息或进行交易等行为或替代行为产生的费用及损失。
        </p>
        <p>4）您对“大麦灵工”平台服务的误解。</p>
        <p>
          5）任何非因“大麦灵工”平台的原因而引起的与“大麦灵工”平台服务有关的其它损失。
        </p>
        <p>
          6、不论在何种情况下，“大麦灵工”平台均不对由于信息网络正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障、罢工、劳动争议、暴乱、起义、骚乱、生产力或生产资料不足、火灾、洪水、风暴、爆炸、战争、政府行为、司法行政机关的命令或第三方的不作为而造成的不能服务或延迟服务承担责任。
        </p>
        <p>七、协议终止</p>
        <p>
          1、您同意，“大麦灵工”平台有权自行全权决定以任何理由不经事先通知的中止、终止向您提供部分或全部“大麦灵工”平台服务，暂时冻结或永久冻结（注销）您的账户在“大麦灵工”平台的权限，且无须为此向您或任何第三方承担任何责任。
        </p>
        <p>
          2、出现以下情况时，“大麦灵工”平台有权直接以注销账户的方式终止本协议，并有权永久冻结（注销）您的账户在“大麦灵工”平台网的权限和收回账户对应的“大麦灵工”平台昵称：
        </p>
        <p>
          1）“大麦灵工”平台终止向您提供服务后，您涉嫌再一次直接或间接以他人名义注册为“大麦灵工”平台用户的；
        </p>
        <p>
          2）您提供的电子邮箱不存在或无法接收电子邮件，且没有任何其他方式可以与您进行联系，或“大麦灵工”平台以其它联系方式通知您更改电子邮件信息，而您在“大麦灵工”平台通知后三个工作日内仍未更改为有效的电子邮箱的。
        </p>
        <p>3）您提供的用户信息中的主要内容不真实或不准确或不及时或不完整；</p>
        <p>
          4）本协议（含规则）变更时，您明示并通知“大麦灵工”平台不愿接受新的服务协议的；
        </p>
        <p>5）其它“大麦灵工”平台认为应当终止服务的情况。</p>
        <p>
          6）您若反对任何服务条款的建议或对后来的条款修改有异议，或对“大麦灵工”平台服务不满，您有以下权利：
        </p>
        <p>a）不再使用“大麦灵工”平台服务。</p>
        <p>b）结束用户使用“大麦灵工”平台服务的资格。</p>
        <p>c）通告“大麦灵工”平台停止该用户的服务。</p>
        <p>
          结束用户服务后，即协议终止。您使用“大麦灵工”平台服务的权利马上终止。从那时起，“大麦灵工”平台不再对您承担任何义务。
        </p>
        <p>
          3、您的账户服务被终止或者账户在“大麦灵工”平台的权限被永久冻结（注销）后，“大麦灵工”平台没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。
        </p>
        <p>
          4、您同意，您与“大麦灵工”平台的合同关系终止后，“大麦灵工”平台仍享有下列权利：
        </p>
        <p>
          1）继续保存您的提供的信息及您使用“大麦灵工”平台服务期间的所有交易信息。
        </p>
        <p>
          2）您在使用“大麦灵工”平台服务期间存在违法行为或违反本协议和/或规则的行为的，“大麦灵工”平台仍可依据本协议向您主张权利。
        </p>
        <p>
          5、“大麦灵工”平台中止或终止向您提供“大麦灵工”平台服务后，对于您在服务中止或终止之前的交易行为依下列原则处理，您应独力处理并完全承担进行以下处理所产生的任何争议、损失或增加的任何费用，并应确保“大麦灵工”平台免于因此产生任何损失或承担任何费用：
        </p>
        <p>
          1）您在服务中止或终止之前已经上传至“大麦灵工”平台的服务交易尚未交易的，“大麦灵工”平台有权在中止或终止服务的同时删除此项服务交易的相关信息；
        </p>
        <p>
          2）您在服务中止或终止之前已经与其他会员达成服务交易合同，但合同尚未实际履行的，“大麦灵工”平台有权删除该买卖合同及其交易服务的相关信息；
        </p>
        <p>
          3）您在服务中止或终止之前已经与其他会员达成买卖合同且已部分履行的，“大麦灵工”平台可以不删除该项交易，但“大麦灵工”平台网有权在中止或终止服务的同时将相关情形通知您的交易对方。
        </p>
        <p>八、隐私权政策</p>
        <p>
          “大麦灵工”平台将在“大麦灵工”平台公布并不时修订隐私权政策，隐私权政策构成本协议的有效组成部分。
        </p>
        <p>九、知识产权条款</p>
        <p>
          1、本网站之域名、商标，以及本网站内所有信息内容（本网站用户发布的信息除外），包括但不限于网站架构、文字、图片、软件、音频、视频、用户等级/评价体系/信用体系/榜单等数据信息，以及所有本网站使用之所有技术、数据分析模型、计算机软件、数据库（第三方创建并保留所有权和/或知识产权的除外），其所有权知识产权均归属“大麦灵工”平台唯一所有，未经“大麦灵工”平台书面许可，任何人均不得擅自进行全部和局部复制、转载、引用和链接等使用和处分。
        </p>
        <p>
          2、用户在本网站发布的任何信息和内容（用户隐私信息除外），包括但不限于文字、图片、软件、音频、视频等，均被视为永久、免费许可“大麦灵工”平台自行或许可其关联方、业务合作方、宣传方在法律规定范围内使用和处分。
        </p>
        <p>
          3、任何单位或者个人不得以任何方式引诱本网站用户或者第三方复制转载本网站之信息内容，或者同意该用户或者第三方复制转载本网站内容。
        </p>
        <p>
          4、任何违反本站知识产权声明的行为，本站保留进一步追究法律责任的权利。
        </p>
        <p>十、法律适用、管辖与其他</p>
        <p>
          1、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国大陆地区法律，如无相关法律规定的，则应参照通用国际商业惯例或行业惯例。
        </p>
        <p>
          2、因本协议产生之争议需根据您使用的服务归属的平台确定具体的争议对象，因您使用“大麦灵工”平台服务所产生的争议应由“大麦灵工”平台的经营者与您直接沟通并处理。一旦产生不可调和的争议，您与“大麦灵工”平台的经营者均同意该争议统一归“大麦灵工”平台平台所在地相应级别的法院管辖。
        </p>
        <p>3、本协议以其中文版本为最终生效版本。</p>
        <p>签署声明：</p>
        <p>
          您已经充分知悉且已理解本协议全部内容，保证网络流程操作者已获有效授权并足以代表自己或者授权人订立本协议。
        </p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Agreement',
  props: ['platformId', 'type', 'agreementVisible'],
  data() {
    return {};
  },
  watch: {
    agreementVisible: function (n, o) {
      n && (document.querySelector('#content').scrollTop = 0);
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';
.Authentication {
  .agreement {
    .content {
      max-height: 3.2rem;
      margin: 0.2rem 0;
      padding: 0 0.2rem;
      font-size: 0.12rem;
      overflow-y: auto;

      p {
        margin: 0.2rem 0;
        font-size: 0.14rem;
        &.title {
          margin: 0;
          padding: 0;
          color: $font;
          font-size: 0.16rem;
        }
      }
    }
  }
}
</style>
