<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Dialog } from 'vant';
Vue.use(Dialog);

export default {
  name: 'App',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  created() {
    let pid = 1;
    this.platformId = 1;
    this.$store.commit('setPlatformId', pid);
    sessionStorage.setItem('platformId', pid);
    // console.log(pid);

    if (sessionStorage.getItem('userInfo')) {
      // 刷新的时候从缓存取用户信息
      let userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      this.$store.commit('setUserInfo', {
        userInfo,
      });
      if (!userInfo.idNoStatus) {
        // 该用户未认证 跳转认证
        this.$route.name != 'authentication' &&
          this.$router.push('authentication');
      }
      return;
    }

    if (!this.userInfo.openId) {
      this.$router.push('loading');
    }
  },
};
</script>
