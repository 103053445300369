<template>
  <div class="SalaryDetail">
    <div class="top">
      <p>{{ $dayjs(detail.month).format('YYYY年M月') }}</p>
    </div>
    <van-cell-group :border="false">
      <van-field
        v-for="(value, name, index) in JSON.parse(detail.content)"
        :key="index"
        :label="name"
        readonly
      >
        <template #input>
          <span>{{ value }}</span>
        </template>
      </van-field>
    </van-cell-group>
  </div>
</template>

<script>
import Vue from 'vue';
import { Field } from 'vant';

Vue.use(Field);

export default {
  name: 'SalaryDetail',
  data() {
    return {
      detail: {},
    };
  },
  created() {
    let obj = JSON.parse(sessionStorage.getItem('salaryDetail'));
    this.detail = obj;
  },
  methods: {},
};
</script>

<style lang="scss">
@import '../styles/vars';

.SalaryDetail {
  min-height: 100vh;

  .top {
    height: 1.5rem;
    background: linear-gradient(118deg, #00b781 0%, #03b897 100%);

    p {
      padding-top: 0.32rem;
      padding-left: 0.16rem;
      color: #fff;
      font-size: 0.27rem;
      font-weight: bold;
    }
  }

  .van-cell-group {
    margin: -0.54rem 0.16rem 0;
    border-radius: 0.06rem;
    box-shadow: 0 0 0.19rem 0 rgba(#000, 0.04);
  }

  .van-cell {
    background: none;
    padding: 0.16rem;
  }

  .van-field__label {
    color: #4e5969;
    font-size: 0.16rem;
  }

  .van-field__control {
    justify-content: flex-end;
    font-size: 0.16rem;
    font-weight: 500;
  }
}
</style>
