<template>
  <div class="MyInfo">
    <div class="title">基础信息</div>
    <van-cell-group :border="false">
      <van-field
        label="姓名"
        v-model="form.name"
        input-align="right"
        disabled
      />
      <van-field
        label="联系方式"
        v-model="form.phone"
        input-align="right"
        disabled
      />
      <van-field
        label="身份证号"
        v-model="form.idNo"
        input-align="right"
        disabled
      />
      <van-field label="身高" v-model="form.bodyHigh" input-align="right" />
      <van-field label="体重" v-model="form.bodyWeight" input-align="right" />
      <van-field label="健康证" name="healthCertificateFile">
        <template #input>
          <van-uploader
            v-model="healthCertificateFile"
            max-count="1"
            :before-read="beforeRead"
            :after-read="afterRead"
            @delete="form.healthCertificateFile = ''"
          />
        </template>
      </van-field>
    </van-cell-group>

    <div class="btns">
      <van-button @click="$router.go(-1)">返回</van-button>
      <van-button type="primary" :loading="isLoading" @click="handleSubmit"
        >确定</van-button
      >
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import { Button, Divider, List, Toast } from 'vant';
import Compressor from 'compressorjs';
import OSS from 'ali-oss';
import { getOssToken } from '../utils/getOssToken';

Vue.use(Button).use(Divider).use(List).use(Toast);

export default {
  name: 'MyInfo',
  data() {
    return {
      form: {
        accountId: null,
        healthCertificateFile: '',
      },
      healthCertificateFile: [],
      randomString: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      platformId: (state) => state.platformId,
    }),
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);
  },
  methods: {
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;

        this.getInfo();
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    async getInfo() {
      const data = await this.$axios.post(
        '/tax/wechatController/wechat/getUser',
        {
          openId: this.userInfo.openId,
          platformId: this.platformId,
        }
      );
      if (data.success) {
        this.form = data.data;
        if (data.data.healthCertificateFile) {
          this.getFile(data.data.healthCertificateFile);
        }
      }
    },
    async getFile(filename) {
      let url = await this.clientOSS.signatureUrl(filename, {
        'content-disposition':
          'attachment; filename=' + encodeURIComponent(filename),
      });
      this.healthCertificateFile.push({ url });
    },
    beforeRead(file) {
      console.log('beforeRead', file);
      if (!file.type.includes('image')) {
        Toast('请上传图片格式的文件');
        return false;
      }
      return true;
    },
    async afterRead(file) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        path = `personal/${date}/${t}-healthCertificateFile-${image.name}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        this.form.healthCertificateFile = res.name;
      } else {
        this.form.healthCertificateFile = '';
        this.healthCertificateFile = [];
        Toast('上传失败');
      }
      this.uploading.clear();
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    async handleSubmit() {
      this.isLoading = true;
      let postData = { ...this.form };
      const data = await this.$axios.post(
        '/tax/wechatController/wechat/saveUser',
        postData
      );
      if (data.success) {
        Toast({
          message: data.rtnMessage,
          type: 'success',
          onClose: () => {
            this.$router.go(-1);
          },
        });
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '../styles/vars';

.MyInfo {
  min-height: 100vh;
  background: #fff;

  .title {
    line-height: 0.2rem;
    padding: 0.22rem 0.16rem 0.08rem;
    color: #939aa3;
    font-size: 0.14rem;
  }

  .van-cell {
    line-height: 0.22rem;
    padding: 0.16rem;

    &::after {
      right: 0;
    }

    .van-field__label {
      color: $font;
      font-size: 0.16rem;

      em {
        display: inline-block;
        margin-left: 0.06rem;
        color: $red;
        font-style: normal;
        vertical-align: top;
      }
    }

    .van-field__control {
      color: #86909c;
      font-size: 0.14rem;
    }
  }

  .btns {
    padding-top: 0.72rem;
    padding-bottom: 0.32rem;
    text-align: center;

    .van-button {
      width: 1.66rem;
      margin: 0 0.06rem;

      &:first-child {
        background: #e7f6f1;
        border-color: #e7f6f1;
        color: $primary;
      }
    }
  }
}
</style>
